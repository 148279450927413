import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/modify_pwd',
    component: () => import('@/views/login/modify_pwd'),
    hidden: true
  },
  {
    path: '/',
    name: 'Home',
    // redirect: '/welcome',
    component: () => import('../views/Home'),
    children: [
      {
        path: '/admin/index',
        component: () =>
          import('../views/admin/index')
      },
      {
        path: '/news/index',
        component: () =>
          import('../views/news/index')
      },
      {
        path: '/news/type',
        component: () =>
          import('../views/news/type')
      },
    ]
  },
  {
    path: '*',
    component: () => import('@/views/404'),
    hidden: true
  }

]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
const router = new VueRouter({
  mode: 'history',  //去掉url中的#
  routes
})

//路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/') return next('/login')
  if (to.path === '/login') return next()
  // const tokenStr = window.sessionStorage.getItem('token')
  // const cc = window.cookie
  // if (!tokenStr) return next('/login')
  next()
})
export default router
