import Vue from 'vue'
Vue.filter('dateFormat', (originVal) => {
    if (originVal && originVal.length == '13') {
        originVal = originVal / 1000;
    }
    const dt = new Date(originVal * 1000)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
Vue.filter('dateFormatymd', (originVal) => {
    //  console.log(originVal.length);
    if (originVal && originVal.length == '13') {
        originVal = originVal / 1000;
    }

    const dt = new Date(originVal * 1000)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    return `${y}-${m}-${d}`
})