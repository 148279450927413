import Vue from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'
import router from '../router'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_HOST, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000, // request timeout
    withCredentials: true
})
// 请求头拦截
service.interceptors.request.use(config => {
    NProgress.start()
    config.headers.Authorization = window.sessionStorage.getItem('token')
    return config
})

service.interceptors.response.use(config => {
    NProgress.done()
    const res = config.data
    if (res.status == 'need_login') {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    }
    return config
})

Vue.prototype.$http = service