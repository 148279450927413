import Vue from 'vue'

import './assets/css/global.css'
import qs from 'qs'
import './plugins/element.js'

import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/axios'
import './plugins/editor'
import './plugins/tools'

Vue.prototype.$qs = qs

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
